import { render, staticRenderFns } from "./GuestBox.vue?vue&type=template&id=b8c296d2&scoped=true&"
import script from "./GuestBox.vue?vue&type=script&lang=js&"
export * from "./GuestBox.vue?vue&type=script&lang=js&"
import style0 from "./GuestBox.vue?vue&type=style&index=0&id=b8c296d2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../var/task/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8c296d2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/task/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b8c296d2')) {
      api.createRecord('b8c296d2', component.options)
    } else {
      api.reload('b8c296d2', component.options)
    }
    module.hot.accept("./GuestBox.vue?vue&type=template&id=b8c296d2&scoped=true&", function () {
      api.rerender('b8c296d2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "tmp/tmp-81MvBVnUrV3H7/JS/comp/GuestBox.vue"
export default component.exports